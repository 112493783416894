/*
 * 업무구분: 공통
 * 화 면 명: MSPBC402M
 * 화면설명: 컨설턴트 정보관리 > 상세정보
 * 작 성 일: 2023.4
 * 작 성 자: 조견진
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="컨설턴트 정보" >

    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <!-- 검색결과 영역 -->
      <ur-box-container alignV="center" componentid="" direction="row" class="gray-info-bar"> 
        <div  @click="fn_Open511Pop(consultantInfo.userNm, consultantInfo.userEno)">
          <span class="title">{{consultantInfo.userNm}}</span>
          <em>|</em><span class="">{{consultantInfo.userEno}}</span>
          </div>
      </ur-box-container>

      <!-- tab01 -->
      <mo-tab-box default-idx="1" class="ns-move-tab-box tween">
        <mo-tab-label idx="1" @click="fn_tabIdx('1')">기본정보</mo-tab-label>
        <mo-tab-label idx="2" @click="fn_tabIdx('2')">발행서버변경</mo-tab-label>
      </mo-tab-box>
    </template>

    <!-- Content영역 -->
    
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">  
      <!-- tab01 -->
      <ur-box-container direction="column" alignV="start" v-if="tab_index === '1'">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-add-area">

          <ur-box-container alignV="start" componentid="" direction="column" class="">
            <span class="label-title">조직번호</span>  <!-- 레이블 -->
            <mo-text-field  class="form-input-name full" :value="lv_defUserVo.fofOrgNo+'  '+lv_defUserVo.orgNm" underline placeholder="" readonly/>            
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
            <span class="label-title">휴대폰번호</span>  <!-- 레이블 -->
            <mo-decimal-field numeric v-model="celnoNew" mask="###-####-####" maxlength="13" @blur="fn_ModifyBtnShow" @focus="fn_ModifyBtnHide" clearable underline placeholder="숫자만 입력하세요" class="form-input-name full"/>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
            <span class="label-title">이메일</span>  <!-- 레이블 -->
            <mo-text-field  class="form-input-name full" v-model="email" underline placeholder="" readonly/>
          </ur-box-container>      
        </ur-box-container>
      </ur-box-container>

      <!-- tab02 -->
      <ur-box-container direction="column" alignV="start" v-if="tab_index === '2'">
        <ur-box-container alignV="start" componentid="" direction="column" class="card-list">
          <div class="card-item">
            <span class="crTy-bk7 w80px">조직번호</span>
            <span>{{lv_UserVo.orgNm}}({{lv_UserVo.orgNo}})</span>
          </div>
          <div class="card-item mt10 fex-rw">
            <span class="crTy-bk7 w80px ">발행지점</span>
            <span>{{lv_UserVo.pblOrgNm}}({{lv_UserVo.pblOrgNo}})</span>
          </div>
          <div class="card-item mt10 fex-rw">
            <span class="crTy-bk7 w80px">서버</span>
            <span>{{lv_UserVo.pblSvrIp}}</span>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="mt25">
            <mo-text-field  class="form-input-name full" v-model="searchKeyword" underline searchable :clearable="isClearable" placeholder="조직명 또는 조직코드를 입력해 주세요" @click-icon="fn_schB()" />
          </ur-box-container>  
          <ur-box-container v-show="bcOrgSVOList.length > 0" alignV="start" componentid="" direction="column" class="mt20">
            <msp-bottom-select class="ns-dropdown-sheet" :items="bcOrgSVOList" v-model="bcOrgSelectVal" underline closeBtn bottom-title="조직 선택" ref='s1' scrolling placeholder="조직 선택"/>
          </ur-box-container>
          <ur-box-container v-show="selList.length > 0" alignV="start" componentid="" direction="column" class="mt20">
            <msp-bottom-select class="ns-dropdown-sheet" :items="selList" v-model="serverSelect" :itemValue="'key'" :itemText="'label'" ref='s2' underline closeBtn bottom-title="발행서버 선택" scrolling placeholder="발행서버 선택"/>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>     
    </ur-box-container>
    <!-- Content영역 end -->

    <!-- 버튼 -->
    <ur-box-container alignV="start" v-show="(tab_index === '2' && pblSvrOrg != '' && pblSvrSno != '') || (tab_index === '1' && isModifyBtn === true)" componentid="" direction="column" class="ns-btn-relative noshadow">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_uptCall()">변경</mo-button>
      </div>
    </ur-box-container>
    <!-- 스크롤 List -->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  // import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC402M",  // this.constructor.extendOptions.name
    screenId: "MSPBC402M",
    components: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기
      let tmp = this.$route.params
      console.log('##### DATA FROM MSPBC400M ===>', tmp.userEno)
      this.consultantInfo = tmp
      this.fn_SelcnstSvrDtlData()
      this.fn_SelcnstInfoCall()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isModifyBtn: true,
        isClearable: false,
        tab_index: '1', // tab contant 선택
        lv_defUserVo: {userEno: '' }, // 기본정보
        celno: '',
        celnoNew: '',
        email: '',
  // old move
      consultantInfo: {},      
      lv_UserVo: {},
      // 더보기 key
      // stndKeyList: [],
      // 페이지 카운트
      pageRowCnt: '100',
      bcOrgSVO: [], // 조직선택목록
      bcOrgSVOList: [],
      bcOrgSelectVal:'',
      searchKeyword: '',
      searchWord: '',
      // selectItems: [],
      isShowEmpty: true,
      serverSelect: '',
      serverList: [],
      selList: [],
      pblSvrSno: '',
      pblSvrIp: '',
      pblSvrOrg: ''
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    watch: {
      consultantInfo () {
        if (this.consultantInfo.length === 0) {
          this.isShowEmpty = true
        } else {
          this.isShowEmpty = false
        }
      },
      searchKeyword () {
        // this.selectItems = []
        if (this.searchKeyword === '') {
          this.isClearable = false
          this.searchWord = ''
        }else{
          this.isClearable = true
        }
      },
      bcOrgSelectVal: function (val) {
        this.pblSvrOrg = ''
        this.serverSelect = ''
        this.selList.splice(0, this.selList.length)
      // console.log(' >>>>>>>>>>>>>>>>>>>>>>>>> val=' + val)
        if (val !== '') {
          this.pblSvrOrg = val
          this.fn_pblSvrSearch()
        }
      },
      serverSelect: function (val) {
        // console.log(' serverSelect >>>>>>>>>>>>>>>>>>>>>>>>> val=' + val)
        if (val !== ''){ 
        this.pblSvrSno = val
        }
      },
      dataItem: function () {
        if ( this.dataItem !== '0') {
          this.isShowEmpty = true
        } else {
          this.isShowEmpty = false
        }
      }
    },
    methods: {
      fn_Open511Pop (cnsltNm, cnsltNo) {
        this.lv_popup511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: cnsltNm,
            pCnsltNo: cnsltNo,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              console.log("onPopupConfirm");
              this.$bottomModal.close(this.lv_popup511);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_popup511);
            }
          }
        });
      },
      fn_ModifyBtnHide () {
        this.isModifyBtn = false
        // this.$commonUtil.setHiddenPlugin(false)
      },
      fn_ModifyBtnShow () {
        // console.log('***************** fn_ModifyBtnShow=' + this.celnoNew)
        this.isModifyBtn = true
      },
      fn_tabIdx (str) {
          if (str === '1') {
          this.tab_index = '1'
        } else {
          this.celnoNew = this.celno 
          this.tab_index = '2'
        }
      },
      fn_SelcnstInfoCall () {
        let lv_Vm = this
        let pParams = {userEno:this.consultantInfo.userEno}
        const trnstId = 'txTSSBC17S5'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response && response.body ) {
              lv_Vm.lv_defUserVo = response.body
              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_defUserVo.celno.trim())) {
                lv_Vm.celno = lv_Vm.$bizUtil.telNoWithHyphen(lv_Vm.lv_defUserVo.celno)
                lv_Vm.celno = lv_Vm.celno.replaceAll('-','')
                lv_Vm.celnoNew = lv_Vm.celno               
              }
              lv_Vm.email = lv_Vm.fn_GetEmailAddr(lv_Vm.lv_defUserVo)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_GetEmailAddr ( pObj ) {
        let lv_mail = ''
        if ( pObj.emailAddr && !this.$bizUtil.isEmpty(pObj.emailAddr.trim())) {
          lv_mail = pObj.emailAddr + '@' + this.fn_GetEmailDomain()
        } else {
          lv_mail = ''
        }
        return lv_mail
      },
      fn_GetEmailDomain () {
        if ( this.getStore('userInfo').getters.getUserInfo.isFc === 'Y' ) {
          return 'samsunglife.com'
        } else {
          return 'samsung.com'
        }
      },
      fn_uptCall () {
        console.log('fn_uptCall!!!! , tab_index=' + this.tab_index)
        if( this.fn_validDataSet(this.tab_index) ) {
          this.fn_SaveUserInfo(this.tab_index)
        }
      },
      fn_validDataSet(tabIdx){
        if(tabIdx === '1'){
          console.log('fn_validDataSet!!! celno=' + this.celno + ', celnoNew=' + this.celnoNew)
          let celnoNewf = this.celnoNew
          if(celnoNewf.length < 11){
            this.getStore('toast').dispatch('ADD', '유효한 휴대폰번호를 입력하세요.')
            return false
          }
          if(this.celno.replaceAll('-','') === celnoNewf.replaceAll('-','')){
            this.getStore('toast').dispatch('ADD', '기존 휴대폰번호와 동일합니다.')
            return false
          }
        }else {
          if(this.pblSvrSno === '' || this.pblSvrOrg === ''){
            this.getStore('toast').dispatch('ADD', '조직검색하여 ,발행서버를 선택해 주세요.')
            return false
           }
        }
        return true
      },
      fn_SelcnstSvrDtlData () { // 선택된 컨설턴트의 발생서버 상세정보 조회
        let lv_Vm = this
        let pParams = {userEno: this.consultantInfo.userEno}
        const trnstId = 'txTSSBC17S2'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            lv_Vm.lv_UserVo = response.body
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },
    fn_schB () {
      console.log('fn_schB!!!!!' + this.searchKeyword)
      // this.stndKeyVal = 'start' // 더보기 최초조회
      // this.stndKeyList = [] // 초기로드 or 조회버튼시
      this.bcOrgSVO = []
      this.bcOrgSelectVal = ''
      if (this.searchKeyword !== '') {
        this.bcOrgSVOList = []
        this.pblSvrOrg = '' 
        this.pblSvrSno = ''
        this.fn_orgSearch()
      }
    },
    /******************************************************************************
    * Function명  : fn_orgSearch
    * 설명        : CnsltNm ( 조직리스트 조회 )
    * Params      :  (inVo) 
    ******************************************************************************/
    fn_orgSearch () {
      // if (this.stndKeyVal === 'end') return
      const trnstId = 'txTSSBC17S3'// 조직검색
      const auth = 'S'

      var lv_Vm = this
      let pParams = {cnsltNm: this.searchKeyword}

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            let responseList = response.body.iCFofVO
            if (responseList !== null) {
              console.log('responseList.length=' + responseList.length)          
             let rtnData = []
              for ( let oData of responseList ) {
                rtnData.push( {value: oData.orgNo, text: oData.orgNo + '  ' + oData.orgNm })
              }
              lv_Vm.bcOrgSVOList = rtnData

              lv_Vm.$refs['s1'].show()

            }else{ // 조직검색결과 값이 없는경우 
              lv_Vm.fn_BottomAlert()
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    fn_BottomAlert () {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "알림",
            content: '검색결과가 없습니다.'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
    /******************************************************************************
    * Function명  : fn_pblSvrSearch
    * 설명        : fofOrgNo ( 발행서버 조직 )
    * Params      : ICPblSvrInfoVO (inVo) 
    ******************************************************************************/
    fn_pblSvrSearch () {
      console.log('fn_pblsvrSearch.this.pblSvrOrg=' + this.pblSvrOrg)
      let lv_Vm = this
      let pParams = {fofOrgNo: this.pblSvrOrg}
      const trnstId = 'txTSSBC17S4'
      const auth = 'S'
      this.selList.splice(0, this.selList.length)
      this.serverSelect = ''

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let rtnData = []
          for ( let oData of response.body.iCPblSvrInfoVO ) {
            rtnData.push( {key: oData.pblSvrSno, label: oData.pblSvrIp + '(' + oData.pblSvrExpln + ')'})
          }
          lv_Vm.selList = rtnData
          lv_Vm.serverList = response.body.iCPblSvrInfoVO
          if(rtnData.length > 0){
            lv_Vm.$refs['s2'].show()
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_SaveUserInfo
    * 설명       : 수정 요청 
    * tabIdx : 1=기본정보변경(celno), 2=발행서버변경
    ******************************************************************************/
    fn_SaveUserInfo (tabIdx) {
      let lv_Vm = this
      let trnstId = 'txTSSBC17U5'
      const auth = 'U'
      let pParams = {userEno: lv_Vm.consultantInfo.userEno,
        pblSvrSno: lv_Vm.pblSvrSno,
        pblOrgNo: lv_Vm.pblSvrOrg,
        prcsfCd: 'U'}
      
      console.log('fn_SaveUserInfo >>> lv_Vm.pblSvrSno=' + lv_Vm.pblSvrSno)

      if(tabIdx === '1'){ //기본정보 변경인 경우 
        trnstId = 'txTSSBC17U6'
        pParams = {userEno: lv_Vm.consultantInfo.userEno 
                  ,celno: lv_Vm.celnoNew.replaceAll('-',''), prcsfCd: 'U'}
      }
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.msgComm.msgCd === 'EFWS026') { //EFWS026            
            lv_Vm.getStore('toast').dispatch('ADD', '변경되었습니다.')
          }else{
            lv_Vm.getStore('toast').dispatch('ADD', '실패하였습니다.')
          }          

          if(tabIdx === '1'){ //기본정보 변경인 경우 
            if( response.body != null) {
                lv_Vm.lv_UserVo = response.body
            }
            lv_Vm.fn_SelcnstInfoCall()
          }else {
              lv_Vm.fn_SelcnstSvrDtlData()
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      }
    }
  }
</script>
<style scoped>
</style>