/*
 * 업무구분: 공통
 * 화 면 명: MSPBC400M
 * 화면설명: 컨설턴트 기본정보 > 메인
 * 작 성 일: 2023.4
 * 작 성 자: 조견진
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="컨설턴트 기본정보" action-type="search" @on-header-left-click="fn_BackBtnClicked" @on-scroll-bottom="fn_moreData()"  @action-search-click="fn_PopCall()" >
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <!-- 검색결과 영역 -->
      <ur-box-container alignV="start" v-show="Iv_schKeyWord !== ''" direction="column" class="search-result-box bd-b-Ty1 bd-T-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" @click="fn_PopCall()">
            <span class="txt">{{Iv_schKeyWord}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총<span class="count">{{totalCnt}}</span>건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">  
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-list">
          <mo-list :list-data="this.bcUserBaseInfoCommSVO">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents" @click="fn_MoveToMSPBC402M(item)">
                  <div class="list-item__contents__info font19">
                    <span>{{item.userEno}}</span>
                    <span class="fr">{{item.userNm}}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>

        </ur-box-container>
        <!-- no-data --> 
        <ur-box-container alignV="start" v-show="totalCnt===0" componentid="" direction="column" class="no-data">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="ns-ftcr-gray"><p class="mt20">조회된 컨설턴트가 없어요.</p></span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- 스크롤 List -->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBC402M from '@/ui/bc/MSPBC402M' // 상세정보
  import MSPBC401P from '@/ui/bc/MSPBC401P' // 조회팝업
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPBC400M",  // this.constructor.extendOptions.name
    screenId: "MSPBC400M",
    components: {
      MSPBC402M,
      MSPBC401P,
      MspBottomSelect
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      let isManager = this.getStore('userInfo').getters.getUserInfo.isBMngr
      console.log('created()>>>>>>>>> isManager:' +isManager) // Y/N
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기

      let tmp = this.$route.params
      console.log('##### DATA FROM  ======>', tmp)
      this.fn_initSearch()      
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isPopSch:false,
        isScrolled: false,
        Iv_schKeyWord: '',
    // old move
      isLoading: false,
      //   mockData: ''
      selectItems: null,
      schKeyWord: '',
      paramschKeyWord: '',
      sortConsultantCd: {isAsc: true},
      // 더보기 key
      stndKeyList: [],
      // 페이지 카운트
      pageRowCnt: '20',
      // 인피니트 바인딩 리스트VO
      bcUserBaseInfoCommSVO: [],
      // 누적 총 조회건수
      totCnt: 0,
      sub_menu_item: '0'
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {           
      isClickedItem () {
        return this.selectItems
      }, 
      totalCnt () {
        return this.bcUserBaseInfoCommSVO==null?0:this.bcUserBaseInfoCommSVO.length
      }
    },
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_BackBtnClicked (){
        if(this.isPopSch){ // 검색팝업에서 검색을한적이 있는경우 초기화면으로 (이전)
          this.Iv_schKeyWord = ''
          this.schKeyWord = ''
          this.fn_initSearch()
          this.isPopSch = false
        }else{
          this.$router.go(-1)
        }
      },
      fn_OnScroll (scrollLeft, scrollTop) {
      this.isScrolled = scrollTop > 0
      },
      hasSelectItem () {
      return !!this.selectItems.length > 0
    },
    fn_initSearch () {
      this.paramschKeyWord = this.schKeyWord
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [] // 초기로드 or 조회버튼시
      this.bcUserBaseInfoCommSVO = []
      // this.selectItems = null
      // this.isClickedItem()
      this.fn_getBranchList() // 서비스 호출
    },
    /******************************************************************************
      * Function명 : fn_getBranchList
      * 설명       :
    ******************************************************************************/
    fn_getBranchList () {
      console.log('fn_getBranchList.stndKeyVal=' + this.stndKeyVal)
      if (this.stndKeyVal === 'end') return
      const trnstId = 'txTSSBC17S1'// 목록 조회
      const auth = 'S'

      var lv_Vm = this
      let pParams = {userNm: this.schKeyWord}

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_Vm.totCnt = 0
          // 서버 데이터 가져오기
          if (response.body !== null) {
            let responseList = response.body.iCUserBaseInfoVO
            if (responseList !== null) {
              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.bcUserBaseInfoCommSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.bcUserBaseInfoCommSVO = responseList
              }
              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.bcUserBaseInfoCommSVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }
            }
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // }
    },
    /******************************************************************************
    * Function명 : fn_Close
    * 설명       : 이전 페이지로 이동한다.
    ******************************************************************************/
    fn_Close () {
      this.$router.go(-1)
    },
    /******************************************************************************
      * Function명 : fn_moreData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_moreData() {
      window.vue.getStore('progress').dispatch('PART')

      if (!this.isLoading) {
        this.fn_getBranchList()
      }      
    },
    fn_subMenu () {
      // this.selectItems.splice(0, this.selectItems.length)
      this.sub_menu_item = (parseInt(this.sub_menu_item) + 1) + ''
    },
    fn_MoveToMSPBC402M (item) {     
        this.$router.push( {name: 'MSPBC402M', params: item })
    },
    fn_PopCall() {
      this.popups = this.$bottomModal.open(MSPBC401P,{
        properties: {
          title: '검색'
          ,params: {}
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          initDataPopup: (popdata) => {
            this.$bottomModal.close(this.popups)
            console.log("initDataPopup.popdata="+popdata.schKeyWord)      
            this.isPopSch =  popdata.isPopSch // 검색팝업에서 조회를 했음(true)
            this.Iv_schKeyWord = popdata.schKeyWord===''?'전체':popdata.schKeyWord
            this.schKeyWord = this.Iv_schKeyWord==='전체'?'':this.Iv_schKeyWord
            this.fn_initSearch()
          }
        }
      })
    }
    }
  }
</script>
<style scoped>
</style>