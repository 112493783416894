/*
 * 업무구분: 공통
 * 화 면 명: MSPBC401P
 * 화면설명: 컨설턴트 정보관리 > 메인 > 조회 팝업
 * 작 성 일: 2023.4
 * 작 성 자: 조견진
 */
<template>
  <ur-page-container class="msp" title="컨설턴트 조회" :show-title="true" type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="sch-box">
          <mo-text-field class="form-input-name" ref="text" v-model="Iv_schKeyWord" @focus="fn_ControlKeyPad" underline clearable placeholder="컨설턴트 코드 또는 이름을 입력해 주세요." />
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_schReset()">초기화</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SearchPop()">검색</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
  export default {
    name: "MSPBC401P",
    screenId: "MSPBC401P",
    components: { },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    data() {
      return {
        foreignCustVo: {}
        ,radioSelect:'N'
        ,Iv_schKeyWord:''
        ,pPraams:{}
      }
    },
    props: {
      title: {
        type: String,
        default: "조회"
      },
      params: {
        type: Object,
        default: {}
      }
    },
    created() {   
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.constructor.extendOptions.name) // 화면 접속기록 남기기 
      this.fn_setTxtFocus()
    },
    computed: {    
    },
    watch: {
    },
    methods: {
      fn_SearchPop() {
        this.pPraams = {schKeyWord: this.Iv_schKeyWord,isPopSch:true}
        this.$emit("initDataPopup", this.pPraams);
      },
      fn_schReset() {
        this.Iv_schKeyWord = ''
        this.fn_setTxtFocus()
      },
      fn_setTxtFocus() {
          this.$refs.text.focus()
      },      
      /******************************************************************************
       * Function명 : fn_ControlKeyPad
       * 설명       : 키보드 입력 시 화면을 키보드 위로 올라오게 처리하는 함수
       ******************************************************************************/
      fn_ControlKeyPad () {
        this.$commonUtil.setHiddenPlugin(false)
      }
    }    
  }
</script>
<style scoped>
</style>